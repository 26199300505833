body {
  margin: 10px;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  background-color: #abdff3;
  background-image: url(./resources/images/sky.png);
  background-position: 20% 56%;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
header {
  margin-top: 40px;
  margin-bottom: 83px;
}




/* MRS-EAVES FONT */
@import url("https://p.typekit.net/p.css?s=1&k=eel6ntc&ht=tk&f=28484.28485&a=52188319&app=typekit&e=css");
@font-face {
font-family:"mrs-eaves";
src:url("https://use.typekit.net/af/23ea79/00000000000000007735b2ff/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/23ea79/00000000000000007735b2ff/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/23ea79/00000000000000007735b2ff/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}
@font-face {
font-family:"mrs-eaves";
src:url("https://use.typekit.net/af/962d4f/00000000000000007735b300/30/l?subset_id=2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/962d4f/00000000000000007735b300/30/d?subset_id=2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/962d4f/00000000000000007735b300/30/a?subset_id=2&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

/* Custom Checkbox Toggles */
.toggler-wrapper {
  display: block;
  width: 45px;
  height: 25px;
  cursor: pointer;
  position: relative;
}
.toggler-wrapper input[type="checkbox"] {
  display: none;
}
.toggler-wrapper input[type="checkbox"]:checked+.toggler-slider {
  background-color: #44cc66;
}
.toggler-wrapper .toggler-slider {
  background-color: #ccc;
  position: absolute;
  border-radius: 100px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.toggler-wrapper .toggler-knob {
  position: absolute;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.toggler-wrapper.style-15 {
  left: 30px;
}
.toggler-wrapper.style-15 input[type="checkbox"]:checked+.toggler-slider:before {
  opacity: 0.5;
}
.toggler-wrapper.style-15 input[type="checkbox"]:checked+.toggler-slider:after {
  opacity: 1;
}
.toggler-wrapper.style-15 input[type="checkbox"]:checked+.toggler-slider .toggler-knob {
  left: calc(100% - 19px - 3px);
}
.toggler-wrapper.style-15 .toggler-slider:before {
  content: 'Off';
  position: absolute;
  top: 50%;
  left: -30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 75%;
  text-transform: uppercase;
  font-weight: 500;
}
.toggler-wrapper.style-15 .toggler-slider:after {
  content: 'On';
  position: absolute;
  top: 50%;
  right: -25px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 75%;
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.5;
}
.toggler-wrapper.style-15 .toggler-knob {
  width: calc(25px - 6px);
  height: calc(25px - 6px);
  border-radius: 50%;
  left: 3px;
  top: 3px;
  background-color: #fff;
}



/* Bookshelf */
.book-shelf {
  display: flex;
  justify-content:space-evenly;
  flex-flow: wrap;
  align-content: center;
  flex-direction: row;
}

/* Cards */
.book {
  width: 350px;
  height: 500px;
}
.book-card {
  width: 300px;
  height: 410px;
  color: black;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding: 16px;
  border-radius: 5px;
  background-color: #d9ddbd;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.53);
}
.book-card .book-card-cover {
  position: relative;
  width: 263px;
  height: 350px;
  margin: 0 auto 8px auto;
  perspective: 1000px;
  cursor: pointer;
}
.book-card .book-card-book {
  height: 100%;
  transform-style: preserve-3d;
  transition: all 250ms ease;
  box-shadow: 5px 6px 10px rgba(0, 0, 0, 0.16), 0 6px 10px rgba(0, 0, 0, 0.53);
}
.book-card .book-card-book-front {
  position: absolute;
  height: 100%;
}
.book-card .book-card-book-back {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform: translateZ(-40px);
}
.book-card .book-card-book-side {
  position: absolute;
  top: 5px;
  bottom: 2px;
  right: -29px;
  width: 40px;
  background-size: 5px;
  background-color: #e1e1e1;
  background-image: linear-gradient(to right, #ccc 35%, #e1e1e1 35%);
  opacity: 0;
  transform: rotate3d(0, 1, 0, 90deg);
}
.book-card .book-card-img {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.book-card .book-card-title {
  font-size: 1.3em;
  margin-bottom: 2px;
  margin-top: 15px;
  font-variant-caps: small-caps;
}
.book-card .book-card-author {
  color: #757575;
  font-size: 1em;
  
}
.book-card-cover:hover .book-card-book {
  transform: rotate3d(0, -1, 0, 30deg) translate(-15px, -30px);
}
.book-card-cover:hover .book-card-book-back {
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.35);
}
.book-card-cover:hover .book-card-book-side {
  opacity: 1;
}

.book-card-cover {
  transition: width 1s, height 1s, left 1s, top 1s;
}
.book-card-cover.opened {
  position: fixed;
  width: 631px;
  height: 855px;
  top: 52px;
  left: 50%;
  z-index: 2;
  transition: width 2s, height 2s, left 2s ease, top 2s ease;
}
.book-card-cover.opened:hover .book-card-book {
  transform: none;
}


/* Opened Book */
.openedBook {
  --x-shadow: -10px;
  --y-shadow: 10px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  height: 1000px;
  width: 1649px;
  color: black;
  background-image: url(./resources/images/Storybook5.png);
  background-size: 1649px 1000px;
  background-repeat: no-repeat;
  background-position: 0;
  z-index: 3;
  filter:drop-shadow(var(--x-shadow) var(--y-shadow) 5px rgba(0, 0, 0, 0.3));

	opacity: 1;
	animation-name: fadeInOpacity;;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

.openedBook.hide {
	opacity: 0;
	animation-name: fadeOutOpacity;;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.bookPage {
  width: 500px;
  background-size: cover;
  /*border: 1px solid #c2b5a3;*/
  font-size: 1.6em;
  text-align: center;
  word-wrap: break-word;
  position: relative;
}
.bookPage.--right {
  border-left: 0;
  /*box-shadow: inset 7px 0 30px -7px rgb(0 0 0 / 40%);*/
}
.bookPage.--left {
  border-right: 0;
  /*box-shadow: inset -7px 0 30px -7px rgb(0 0 0 / 40%);*/
}
.bookPage.--left {
  background-image: url(./resources/images/LeftPage4.png);
}
.bookPage.--right {
  background-image: url(./resources/images/RightPage4.png);
}
.bookPage.--simple {
  background-image: none;
}

.titlePage {
  padding: 30px;
  width: 550px;
  margin: 0;
  text-align: center;

  position: absolute;
  top: 30%;
  transform: translateY(-50%);
}

.pageText {
  padding: 50px;
  padding-top: 100px;
  width: 550px;
  text-align: justify;
  line-height: 2em;
  
  position: relative;
  top: 40%;
  transform: translateY(-50%);
}

.pageNumber {
  position: absolute;
  bottom: 30px;
  right: 50%;
  font-size: 1em;
}

.pageImage {
  padding: 30px;
  
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.titlePageTitle {
  font-family: "mrs-eaves", Georgia, 'Times New Roman', Times, serif;
  font-size: 2.5em;
  font-weight:bold;
}

header h1 {
  font-size: 6em;
  font-weight: 100;
  text-shadow: 4px 4px 5px black;
}

img.ai-image {
  border: 3px solid rgb(0, 0, 0);
  padding: 10px;
}

.test.stf__parent {
  padding-top: 28px;
}

.closeIcon {
  right: 190px;
  top: 50px;
  font-size: 40px;
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 100;
  filter:drop-shadow(5px 5px 5px rgb(87, 71, 66, 0.5))
}

.imgGenError {
  color: red;
}

.optionMenu {
  padding-top: 60px;
  padding-left: 60px;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  text-align: left;
  width: 1300px;
  height: 880px;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  border-radius: 30px;
  box-shadow: 5px 5px 20px black;
  transition: all 0.5s;
  z-index: 100;
}
.optionMenu.hidden {
  position: fixed;
  width: 1px;
  height: 1px;
  top: 0;
  left: 100%;
  transition: all 0.5s;
  opacity: 0;
}
.optionMenu.hidden .optionsCloseIcon {
  display: none;
}
.optionMenu.hidden .stylePicker{
  position: fixed;
  width: 1px;
  height: 1px;
  top: 60px;
  right: 60px;
  left: 95%;
  left: none;
  opacity: 0;
}
.optionMenu h1 {
  font-size: 2.5em;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-variant-caps: small-caps;
  margin-top: 35px;
}
.option {
  
}
.optionLabel {
  font-size: 1.3em;
  padding-top: 30px;
  padding-bottom: 10px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-variant-caps: small-caps;
}
.stylePicker {
  position: absolute;
  top:60px;
  right:60px;
  width: 970px;
  height: 820px;
  background-color: #bdbdbd;
  border-radius: 25px;
}
.stylePickerLabel {
  text-align: center;
  font-size: 3.2em;
  margin-top: 30px;
  font-variant: small-caps;
  font-weight: bold;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.styleOptions {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.promptStyle {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 20px;
}
.selectedPromptStyle {
  border-radius: 5px;
  outline: 10px solid #c0ff00;
  box-shadow: 20px 20px 15px #4c4c4c;
}
.promptStyleLabel {
  position: absolute;
  bottom: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  width: 200px;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  font-variant-caps: all-small-caps;
  text-shadow: 1px 1px 2px black, 0 0 4em lightblue;
  color: white;
  background-color: rgba(71, 58, 72, 0.7);
}
.selectedPromptStyle .promptStyleLabel {
  color: #c0ff00;
}
.promptStyleImg {
  width: 200px;
  height: 200px;
}
#voiceDropdown {
  width: 200px;
  position: relative;
  padding: 5px;
  margin-bottom: 15px;
  height: 40px;
  border: 1px solid gray;
  background: #ebebeb;
  font-size: 1.2em;
}
.optionsCloseIcon {
  right: 10px;
  top: 10px;
  font-size: 40px;
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 100;
  filter:drop-shadow(5px 5px 5px rgb(87, 71, 66, 0.5))
}
#settingsIconDiv {
  position: fixed;
  top: 60px;
  right: 80px;
  width: 160px;
  height: 160px;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 10;
}
#settingsIconDiv:hover {
  transform: rotate(20deg);
  transition: all 0.2s;
}
#settingsIcon {
  filter: drop-shadow(0 0 25px black);
  width: 160px;
}
.headerImg {
  filter: drop-shadow(5px 5px 5px rgb(95, 95, 95));
}
.optionMenu details {
  margin-top: 40px;
  line-height: 1.4em;
  font-size: 1.2em;
}

footer {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.1em;
  font-variant-caps: small-caps;
  text-shadow: 2px 2px 5px black;
}

#debugBar {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 4px;
  background-color: red;
  font-size: 18px;
  color: black;
}
#debugBar.fetchInProgress {
  background-color: greenyellow;
}
#debugBar.hidden {
  display: none;
}


#generateStoryButton {
  width: 400px;
  height: 60px;
  font-size: 2em;
  font-family: "mrs-eaves", Georgia, 'Times New Roman', Times, serif;
  font-variant-caps: small-caps;
  background-color: #d9ddbd;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.53);
  transition: all 0.5s;
  margin-top: 50px;
  margin-bottom: 60px;
}
#generateStoryButton.hidden {
  opacity: 0;
  width: 0;
  height: 0;
  transition: all 0.5s;
  font-size: 1px;
}
.generateStoryCloseIcon {
  right: 10px;
  top: 10px;
  font-size: 40px;
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 100;
  filter:drop-shadow(5px 5px 5px rgb(87, 71, 66, 0.5))
}
#generateStoryForm {
  width: 600px;
  height: 460px;
  padding: 30px 50px;
  background-color: #d9ddbd;
  color: black;
  text-align: left;
  top: 20%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%);
  position: fixed;
  border-radius: 30px;
  box-shadow: 5px 5px 20px black;
  transition: all 0.5s;
  z-index: 100;
  font-family: "mrs-eaves", Georgia, 'Times New Roman', Times, serif;
}
#generateStoryForm.hidden {
  position: fixed;
  width: 1px;
  height: 1px;
  bottom: 0;
  top: 100%;
  left: 50%;
  transition: all 0.5s;
  opacity: 0;
}
#generateStoryFormTitle {
  font-size: 3em;
  font-variant-caps: small-caps;
  text-align: center;
  margin: 20px;
}
#generateStoryForm.hidden .optionsCloseIcon {
  display: none;
}
.generateStoryLabel {
  font-size: 1.3em;
  font-variant-caps: small-caps;
  text-align: center;
}
.generateStoryInput {
  font-size: 1.6em;
  margin-bottom: 20px;
  padding: 10px;
  font-family: "mrs-eaves", Georgia, 'Times New Roman', Times, serif;
  font-variant-caps: small-caps;
  width: 100%;
}
#generateStorySubmitButtonDiv {
  text-align: center;
  margin: 20px;
}
#generateStorySubmitButton {
  align-self: center;
  width: 300px;
  height: 50px;
  font-size: 2em;
  font-family: "mrs-eaves", Georgia, 'Times New Roman', Times, serif;
  font-variant-caps: small-caps;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.53);
  transition: all 0.5s;
}
#loadingGif {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px;
  border-radius: 30px;
  border: 3px solid rgba(0, 0, 0, 0.35);
}
#loadingGif.hidden {
  display: none;
}